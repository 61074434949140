export const endpoint = {
    tickets: {
        create: "/v1/tickets/",
        status: "/v1/tickets/",
        latest: "/v1/tickets/latest",
        allMoney: "/v1/tickets/allMoney",
        summary: "/v1/tickets/summary",
        verify: "/v1/tickets/verify",
    },
    user: {
        login: "/v1/auth/login",
        getCurrentUser: "v1/users/",
    }
}
