@import "./src/scss/variables";

.btn {
    padding: 18px 16px;
    border-radius: 20px;
    background-color: $c_ice;
    color: white;
    font-size: 40px;
    font-weight: 900;
    border: 0;
    cursor: pointer;

    transition: background-color 300ms ease;
    &:hover {
        background-color: $c_brand;
    }

    span {
        font-size: 24px;
        text-transform: uppercase;
    }

    &Selected {
        background-color: $c_accent !important;
    }
}

.customInput {
    border-radius: 20px;
    border: 1px solid $c_ice;
    padding: 1rem;
    display: flex;
    align-items: center;

    textarea {
        text-align: center;
    }

    &Error {
        border-color: $c_danger;
        box-shadow: 0 0 10px 0 $c_danger;
    }

    &Selected {
        border-color: $c_accent;
        border-width: 2px;

        textarea {
            font-size: 24px;
            font-weight: 900;
            height: 40px;
            line-height: 40px;
            min-height: 40px;
            overflow: hidden;
            color: $c_accent;
            margin: 0 10px 0 0;
            display: block;
            text-align: right;
            &:focus {
                color: $c_accent;
            }

            @media all and (min-width: 768px) {
                font-size: 32px;
            }
        }
    }

    &Currency {
        font-size: 24px;
        font-weight: 900;
        color: $c_accent;

        @media all and (min-width: 768px) {
            font-size: 24px;
            top: 2px;
            position: relative;
            text-transform: uppercase;
        }
    }

    textarea {
        padding: 0;
        border: 0;
        resize: none;
        border-radius: 4px;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 94px 94px 94px;
    column-gap: 24px;
    row-gap: 24px;
    justify-items: stretch;
    justify-content: center;

    @media all and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 96px 96px;
    }
}
