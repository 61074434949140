import { AxiosResponse } from "axios";
import {IRequestCreateTicket, IRequestLogin, IRequestVerifyEmail, IResponseLogin} from "../interfaces/api";
import {endpoint} from "../configs/endpoints";
import {Api} from "../respositories/Api";


export class ApiService {

    public static async createTicket(data: IRequestCreateTicket): Promise<AxiosResponse> {
        return await Api.post(data, endpoint.tickets.create);
    }

    public static async getTicketStatus(id: string): Promise<AxiosResponse> {
        return await Api.get(endpoint.tickets.status + id);
    }

    public static async getEmailVerifyStatus(data: IRequestVerifyEmail): Promise<AxiosResponse> {
        return await Api.post(data, endpoint.tickets.verify);
    }

    public static async getLatestOrders(number?: number): Promise<AxiosResponse> {
        let config = {};

        if(number) {
            config = {
                params: {
                    // number: number ?? parseInt(process.env.LATEST_NUMBER)
                }
            }
        }

        return await Api.get(endpoint.tickets.latest, config);
    }

    public static async getAllMoney(): Promise<AxiosResponse> {
        return await Api.get(endpoint.tickets.allMoney);
    }

    public static async userLogin(data: IRequestLogin) {
        return await Api.post(data, endpoint.user.login);
    }

    public static async getSummary(accessToken: string) {
        return await Api.get(endpoint.tickets.summary, {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: 'blob'
        });
    }

}
