import React, {useEffect, useState} from "react";
import {
    useQuery,
    useQueryClient,
} from 'react-query';
import {ApiService} from "../../services/ApiService";
import { useCountUp } from 'react-countup';


export const PaymentsCounter = (): JSX.Element => {

    const countUpRef = React.useRef(null);
    const heartRef = React.useRef(null);
    const queryClient = useQueryClient();

    const { start, pauseResume, reset, update } = useCountUp({
        ref: countUpRef,
        start: 0,
        end: 0,
        duration: 3,
        separator: " ",
        decimal: ",",
        decimals: 0,
        // onUpdate: () => console.log('Updated!'),
        // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });

    const { status, data, error } = useQuery(
        'allMoney',
        async () => {
            const res = await ApiService.getAllMoney();
            return res.data;
        },
        {
            refetchInterval: parseInt(process.env.LATEST_INTERVAL || "20000"),
        }
    )

    function handleOnUpdateValue (data) {
        update(data);
    }

    function pulseHeart () {
        if (heartRef.current) {
            heartRef.current.classList.add("b-hero__heart--pulse");
            window.setTimeout(() => {
                heartRef.current.classList.remove("b-hero__heart--pulse");
            }, 1000);
        }
    }

    useEffect(() => {
        heartRef.current = document.querySelector(".b-hero__heart");

        if (data) {
            handleOnUpdateValue(data);
            pulseHeart();
        }
    }, [data]);



    return (
        <div ref={countUpRef} />
    )
};
