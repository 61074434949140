import React, {useEffect, useState} from "react";
import {PaymentAmount} from "../PaymentAmount/PaymentAmount";
import {PaymentWay} from "../PaymentWay/PaymentWay";
import { useForm, FormProvider } from "react-hook-form";
import {IPaymentFormValues} from "../../interfaces";
import {PAYMENT_MINIMAL, RULES} from "../../constans";
import {PaymentForm} from "../PaymentForm/PaymentForm";
import {ApiService} from "../../services/ApiService";
import {IResponseCreateTicket} from "../../interfaces/api";
import {AxiosError, AxiosResponse} from "axios";
import * as classes from "./payment.module.scss";
import clsx from "clsx";
import sax from "../../../img/sax.png";

const defaultPaymentFormValues:IPaymentFormValues = {
    payment: {
        type: null,
        value: undefined,
        customValue: null,
        selectedCustomValue: false,
        details: {
            name: "",
            email: "",
        }
    },
    consents: {
        isNotAnonymous: false,
        graphicAgreement: false,
        rules: false,
        marketingAgreement: false,
        marketingAgreements: {
            subagreement1: false,
            subagreement2: false,
            subagreement3: false,
        },
    }
}

export const Payment = (): JSX.Element => {

    const methods = useForm<IPaymentFormValues>({
        defaultValues: defaultPaymentFormValues,
        mode: 'onChange',
        shouldFocusError: false,
    });
    const watchAllFields = methods.watch();
    const [isLoading, setIsLoading] = useState(false);


    function onSubmit (data: IPaymentFormValues) {

        setIsLoading(true);

        const buildAgreements = [];
        if (data.consents.isNotAnonymous) {
            buildAgreements
                .push(RULES.isNotAnonymous)
        }
        if (data.consents.graphicAgreement) {
            buildAgreements
                .push(RULES.graphicAgreement.join(" "))
        }
        if (data.consents.rules) {
            buildAgreements
                .push(RULES.rules)
        }

        const formatRequestData = {
            email: data.payment.details.email,
            name: data.payment.details.name,
            isAnonymous: !data.consents.isNotAnonymous,
            graphicAgreement: data.consents.graphicAgreement,
            agreements: buildAgreements,
            amount: parseFloat( String(data.payment.selectedCustomValue ? data.payment.customValue : data.payment.value) ),
            payMethod: data.payment.type,
        }

        ApiService.createTicket(formatRequestData)
            .then((response: AxiosResponse<IResponseCreateTicket>) => {
                if (response.status === 201 || response.status === 200 ) {
                    if (response.data.paymentUri)
                    // window.open(response.data.paymentUri, '_blank');
                    window.location.href = response.data.paymentUri;
                }
            })
            .catch((error: AxiosError) => {
                setIsLoading(false);
            }).then(() => {

            })
        ;
    }

    return (
        <FormProvider {...methods}>

            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate autoComplete="off" className={clsx(classes.form, "mb-5 pb-1")}>

                <PaymentAmount />

                <PaymentWay />

                <PaymentForm isLoading={isLoading} />

                {/*<div className={"bg-dark text-white my-4"}>*/}
                {/*    <div className={"container"}><pre>{JSON.stringify(watchAllFields, null, 2) }</pre></div>*/}
                {/*</div>*/}

                <img src={sax} alt={""} aria-hidden={true} className={classes.formEye} />

            </form>

        </FormProvider>
    )
};
