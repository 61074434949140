import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

import {PaymentType} from "../../types";
import {isEmail} from "../../validators/isEmail";
import clsx from "clsx";
import tamburine from "../../../img/tamburine.svg";
import * as classes from "./paymentForm.module.scss";
import {RULES} from "../../constans";
import {PaymentFormRules} from "../PaymentFormRules/PaymentFormRules";
import {PaymentFormRules2} from "../PaymentFormRules/PaymentFormRules2";

export const PaymentForm = ({
    isLoading
                            }: {
    isLoading: boolean
}): JSX.Element => {

    const { register, watch, formState: { errors } } = useFormContext();

    const paymentType = watch("payment.type");

    const paymentSelectedCustomValue = watch("payment.selectedCustomValue");
    const paymentValue = watch("payment.value");
    const paymentCustomValue = watch("payment.customValue");


    return (
        <div className={clsx("mt-5 mb-3", {
            ["d-none"] : !paymentType
        })}>
            <div className={"container"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-6"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"mt-3"}>
                                    <label htmlFor="payment.details.name" className="form-label">Wpisz swoje imię, aby otrzymać imienne podziękowanie od&nbsp;Jurka&nbsp;Owsiaka:</label>
                                    <input {...register("payment.details.name", {
                                        required: true
                                    })} type={"text"} className="form-control" id="payment.details.name"
                                           placeholder="Twoje imię" />
                                    {
                                        errors?.payment?.details?.name &&
                                        <div className="invalid-feedback">Podaj swoje imię</div>
                                    }
                                </div>

                            </div>
                            <div className={"col-12"}>
                                <div className={"mt-3"}>
                                    <label htmlFor="payment.details.email" className="form-label">Wpisz adres e-mail</label>
                                    <input {...register("payment.details.email", {
                                        required: true,
                                        validate: isEmail
                                    })} type="email" className="form-control" id="payment.details.email"
                                           placeholder="twoj@email.com" />
                                    {
                                        errors?.payment?.details?.email &&
                                        <div className="invalid-feedback">Wprowadź poprawny e-mail</div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className={"mt-5"}>

                            <PaymentFormRules2 />


                        </div>
                    </div>
                </div>
            </div>
            {
                (
                    errors?.payment?.details || errors?.consents
                    || (errors?.payment && (paymentSelectedCustomValue ? !(!!paymentCustomValue) : !(!!paymentValue)))
                ) &&
                <div className={"container"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-6"}>
                            <div className="invalid-feedback">Popraw błędy w formularzu</div>
                        </div>
                    </div>
                </div>
            }
            <div className={"d-flex justify-content-center mt-5 position-relative"}>
                {
                    isLoading &&
                    <div>Za chwilę przejdziesz do płatności...</div>
                }
                {
                    !isLoading &&
                    <button type={"submit"} className={"u-button"}>Wpłacam teraz</button>
                }
                <img alt="" src={tamburine} className={classes.tamburine} aria-hidden={true} />
            </div>
        </div>
    )
};
