.tamburine {
    width: 139px;
    position: absolute;
    display: none;

    @media all and (min-width: 768px) {
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-340px, -50%);
    }
}

