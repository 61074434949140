export enum PaymentType {
    CARD = "CARD",
    TRANSFER = "PBL",
    BLIK = "BLIK",
}

export enum PayUOrderStatus {
    NEW = "NEW",
    PENDING = "PENDING",
    WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
}
