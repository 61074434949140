.form {
    position: relative;

    h2 {
        font-size: 36px;
    }

    &Eye {
        width: 161px;
        position: absolute;
        left: 55%;
        top: 0;

        display: none;

        @media all and (min-width: 998px) {
            transform: translate(300px, 70px);
            display: block;
        }
    }
}
