import React, {useEffect, useState} from "react";
import {PAYMENT_AMOUNTS, PAYMENT_MINIMAL} from "../../constans";
import * as classes from './paymentAmount.module.scss';
import clsx from 'clsx';
import {useFormContext } from "react-hook-form";
import {priceFormatter} from "../../helpers/priceFormatter";


export const PaymentAmount = ({}: {}): JSX.Element => {

    const { register, setValue, getValues, watch, reset, formState: { errors } } = useFormContext();

    const paymentSelectedCustomValue = watch("payment.selectedCustomValue");
    const paymentValue = watch("payment.value");

    function handleClickAmount (e, amountValue: number | null) {

        setValue("payment.value", amountValue, { shouldValidate: true });
        setValue("payment.customValue", null, { shouldValidate: true });
        setValue("payment.selectedCustomValue", false, { shouldValidate: true });

    }

    function handleValidateCustomAmount (e) {

        if (priceFormatter(e.target.value)) {
            let p = e.target.value;

            setValue("payment.value", null, { shouldValidate: true });
            setValue("payment.selectedCustomValue", true, { shouldValidate: true });
            setValue("payment.customValue",  p ? parseInt(p) : null, { shouldValidate: true });

            if (p > 0) {

            } else {
                setValue("payment.selectedCustomValue", false, { shouldValidate: true });
            }
        } else {
            setValue("payment.customValue",  getValues("payment.customValue"), { shouldValidate: true });
        }
    }

    return (
        <div className={"container my-5"}>
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-10 col-lg-7 col-xl-6"}>

                    <h2 className={"text-center fw-light mb-5"}>Wspieram 32. Finał WOŚP</h2>

                    <div className={classes.grid}>
                        {
                            PAYMENT_AMOUNTS.map( (amount: {value: number, label: string}) => {
                                return (
                                    <button
                                        className={clsx(classes.btn, {
                                            [classes.btnSelected]: paymentValue === amount.value
                                        })}
                                        type={"button"}
                                        onClick={(e) => handleClickAmount(e, amount.value)}
                                        key={amount.value.toString()}
                                    >
                                        {amount.value} <span>zł</span>
                                    </button>
                                )
                            })
                        }
                        <div className={clsx( classes.customInput,  {
                            [classes.customInputError]: errors?.payment?.customValue && paymentSelectedCustomValue,
                            [classes.customInputSelected]: paymentSelectedCustomValue,
                        })}
                        >
                            <textarea
                                id={"payment.customValue"}
                                {...register(
                                    "payment.customValue",
                                    {
                                        required: !!paymentSelectedCustomValue,
                                        min: PAYMENT_MINIMAL
                                    })}
                                className="form-control"
                                maxLength={9}
                                onChange={handleValidateCustomAmount}
                                placeholder={"Wpisz własną kwotę..."}
                            />
                            {
                                paymentSelectedCustomValue &&
                                <span className={classes.customInputCurrency}>zł</span>
                            }

                        </div>
                    </div>

                    {
                        !paymentSelectedCustomValue && errors?.payment?.value &&
                        <div className="invalid-feedback text-center mt-2">Wybierz kwotę</div>
                    }

                    {
                        paymentSelectedCustomValue && errors?.payment?.customValue &&
                        <div className="invalid-feedback text-center mt-2">Wprowadź poprawną kwotę (minimalna kwota do wpłaty to {PAYMENT_MINIMAL} zł).</div>
                    }

                    <input
                        id={"payment.value"}
                        type={"hidden"}
                        {...register(
                            "payment.value",
                            {
                                required: !paymentSelectedCustomValue,
                            })}
                    />





                </div>
            </div>
        </div>
    )
};
