import axios, { AxiosRequestConfig } from "axios";

export class Api {

    private static config: AxiosRequestConfig = {
        // headers: {
        //     'content-type': 'application/json'
        // }
    };

    public static get(endpoint: string, config?: AxiosRequestConfig) {
        return axios.get(`https://api.${process.env.DOMAIN}${endpoint}`, config ?? this.config)
    };

    public static post(data: any, endpoint: string, config?: AxiosRequestConfig) {
        return axios.post(`https://api.${process.env.DOMAIN}${endpoint}`, data, config ?? this.config)
    };
}

