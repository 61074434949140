import {PaymentType} from "../types";

export const PAYMENT_AMOUNTS = [
    {
        value: 20,
        label: "20 zł"
    },
    {
        value: 50,
        label: "50 zł"
    },
    {
        value: 100,
        label: "100 zł"
    },
    {
        value: 150,
        label: "150 zł"
    },
    {
        value: 200,
        label: "200 zł"
    },
];

export const PAYMENT_MINIMAL = 0; // zero or amount

export const PAYMENT_TYPES = [
    {
        label: "Karty i portfele",
        methodParam: "",
        type: PaymentType.CARD
    },
    {
        label: "Przelew internetowy",
        methodParam: "",
        type: PaymentType.TRANSFER
    },
    {
        label: "Blik",
        methodParam: "",
        type: PaymentType.BLIK
    }
];

export const RULES = {
    isNotAnonymous: 'Chcę, aby moje imię było widoczne na liście darczyńców.',
    graphicAgreement: [
        'Chcę otrzymywać od PayU&nbsp;GPO oferty specjalne, kody zniżkowe i inne treści marketingowe dotyczące usług i produktów PayU&nbsp;GPO oraz powiązanych z nimi usług i produktów partnerów PayU&nbsp;GPO, w tym treści dopasowane do mnie w oparciu o informacje dotyczące moich płatności w PayU&nbsp;GPO, za pośrednictwem maila.',
        'Administratorem Twoich danych osobowych jest PayU S.A. z siedzibą w Poznaniu (60-166), przy ul. Grunwaldzkiej 186 („PayU&nbsp;GPO”). Twoje dane osobowe będą przetwarzane przez PayU&nbsp;GPO w celach wskazanych w treści zgód podanych powyżej. ',
        'Udzielenie  zgody jest dobrowolne i w każdej chwili możesz ją wycofać klikając link w otrzymanej wiadomości lub poprzez formularz. Wycofanie tej zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano przed jej cofnięciem.  Więcej informacji o zasadach przetwarzania Twoich danych osobowych przez PayU&nbsp;GPO znajdziesz w <a href="https://poland.payu.com/nota-o-prywatnosci-payu/" target="_blank">Nocie o prywatności PayU</a>.',
    ],
    marketingAgreement: {
        'content': 'Potwierdzam zgody marketingowe i chcę otrzymać jubileuszową grafikę na mój adres email.',
        'subConsents': [
            {
                'content': 'Chcę otrzymywać od PayU&nbsp;GPO informacje drogą elektroniczną o usługach,produktach i wydarzeniach oferowanych przez PayU.'
            },
            {
                'content':  'Chcę otrzymywać od PayU&nbsp;GPO informacje drogą elektroniczną o usługach,produktach i wydarzeniach oferowanych przez partnerów PayU.',
            },
            {
                'content': 'Chcę otrzymywać od PayU&nbsp;GPO dopasowane do mnie maile o usługach,produktach i wydarzeniach oferowanych przez PayU.',
                'extendText': 'Czytaj więcej',
                'extendedContent': 'W tym celu PayU&nbsp;GPO będzie przetwarzać następujące dane osobowe:' +
                    '<ol><li>imię i nazwisko, adres e-mail, adres, informacje o zakupionym produkcie, cenie dostawy, kwocie zakupu, adres IP;</li>' +
                    '<li>historię moich płatności dokonywanych za pośrednictwem PayU z ostatnich 24 miesięcy, która obejmuje takie dane, jak: liczba płatności, rodzaj, data, wartość, zakres; urządzenie, z którego wykonano płatność; numery rachunków bankowych użytych w ramach płatności oraz moje poprzednie wnioski kredytowe, składane przez PayU w ramach „PayU | Raty” lub „PayU | Płacę później”, tj. dane na temat składanego wniosku oraz czy został on przyjęty przez organizację finansującą, informacje na temat liczby instrumentów płatniczych, przechowywanych przeze mnie w PayU oraz terminu od którego były przechowywane.</li></ol>',

            },



        ]
    },
    rules: '*Potwierdzam, że znam i akceptuję <a target="_blank" href="https://poland.payu.com/prywatnosc/">Politykę prywatności</a>.',
}