import React from "react";
import ReactDOM from "react-dom";
import { scrollIntoView } from 'scroll-js';
import {Payment} from "./components/Payment/Payment";
import {PaymentsList} from "./components/PaymentsList/PaymentsList";
const queryClient = new QueryClient();
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import {PaymentsCounter} from "./components/PaymentsCounter/PaymentsCounter";


const paymentCounter = document.querySelector("#payment-counter");
if (paymentCounter) {
    ReactDOM.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <PaymentsCounter />
            </QueryClientProvider>
        </React.StrictMode>,
        paymentCounter
    );
}

const paymentFormElement = document.querySelector("#payment-form");
if (paymentFormElement) {
    ReactDOM.render(
        <React.StrictMode>
            <Payment />
        </React.StrictMode>,
        paymentFormElement
    );
}

const paymentListElement = document.querySelector("#payment-list");
if (paymentListElement) {
    ReactDOM.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <PaymentsList />
            </QueryClientProvider>
        </React.StrictMode>,
        paymentListElement
    );
}


const scrollToTrigger = document.querySelectorAll(".scroll-to");
if (scrollToTrigger) {
    scrollToTrigger.forEach( (triggerEl: HTMLElement) => {
        triggerEl.addEventListener("click", (e) => {
            e.preventDefault();
            if (triggerEl.dataset.targetSelector) {
                const target:HTMLElement = document.querySelector( triggerEl.dataset.targetSelector );
                if (target) {
                    scrollIntoView(target, document.body, { behavior: 'smooth' }).then();
                }
            }
        })
    });
}


const cookieBar = document.querySelector<HTMLElement>("#cookie-bar");

if(cookieBar) {
    const cookieAccepted = document.cookie.indexOf('cookie_seen=1') > -1;
    if(!cookieAccepted) {
        cookieBar.style.display = 'flex';
        const cookieCTA = document.querySelector<HTMLElement>("#cookie-bar a.u-cookie__close");
        cookieCTA.addEventListener("click", (e) => {
            e.preventDefault();
            cookieBar.classList.add('u-cookie--hidden');
            document.cookie = "cookie_seen=1;expires=Thu, 1 Jan 2026 00:00:00 UTC path=/";

        })
    }
}

const hamburger = document.querySelector<HTMLElement>(".p-header__hamburger");

if (hamburger) {
    console.log(hamburger);
    hamburger.addEventListener("click", (e) => {
        e.preventDefault();
        hamburger.classList.toggle('p-header__hamburger--open');
    })
}