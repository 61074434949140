import React from "react";
import {useFormContext} from "react-hook-form";
import {PAYMENT_TYPES} from "../../constans";
import {IPaymentType} from "../../interfaces";
import * as classes from "./paymentWay.module.scss";
import svgCard from "./../../../img/card.svg";
import svgTransfer from "./../../../img/transfer.svg";
import svgBlik from "./../../../img/blik.svg";
import {PaymentType} from "../../types";
import clsx from "clsx";

export const PaymentWay = (): JSX.Element => {

    const { register, formState: { errors }, watch } = useFormContext();
    const paymentTypeSelected = watch("payment.type");

    return (
        <div className={"container pt-3"}>
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-10 col-lg-7 col-xl-6"}>
                    <h2 className={"text-center fw-light"}>Wybierz sposób płatności</h2>

                    <div className={classes.grid}>
                        {
                            PAYMENT_TYPES.map((paymentType: IPaymentType) => {
                                return (
                                    <div key={paymentType.type}>
                                        <input className={classes.labelInput}
                                               {...register("payment.type", {
                                                   required: true
                                               })}
                                               type="radio"
                                               name="payment.type"
                                               value={paymentType.type}
                                               id={paymentType.type}
                                        />
                                        <label
                                            className={
                                                clsx( classes.btn, {
                                                    [classes.btnSelected]: paymentType.type === paymentTypeSelected
                                                })

                                            }
                                            htmlFor={paymentType.type}>
                                            <div className={
                                                clsx(classes.btnIconWrapper, {
                                                    [classes.btnIconWrapperCard] : paymentType.type === PaymentType.CARD,
                                                    [classes.btnIconWrapperTransfer] : paymentType.type === PaymentType.TRANSFER,
                                                    [classes.btnIconWrapperBlik] : paymentType.type === PaymentType.BLIK,
                                                } )}>
                                                {
                                                    paymentType.type === PaymentType.CARD && <img alt={PaymentType.CARD + " payment"} src={svgCard} className={classes.btnIcon} />
                                                }
                                                {
                                                    paymentType.type === PaymentType.TRANSFER && <img alt={PaymentType.TRANSFER + " payment"} src={svgTransfer} className={classes.btnIcon} />
                                                }
                                                {
                                                    paymentType.type === PaymentType.BLIK && <img alt={PaymentType.BLIK + " payment"} src={svgBlik} className={classes.btnIcon} />
                                                }
                                            </div>
                                            <div className={classes.btnLabel}>{paymentType.label}</div>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {
                        errors?.payment?.type &&
                        <div className="invalid-feedback">Wybierz sposób płatności</div>
                    }
                </div>
            </div>
        </div>
    )
};
