@import "src/scss/variables";

.paymentsList {
    background-color: $c_dark;

    padding: 1rem 4rem 1rem 1.5rem;

    @media all and (min-width: 576px) {
        border-radius: 1.5rem;
    }

    &Item {
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        border-bottom: 1px solid rgba(255,255,255, 0.2);

        @media all and (min-width: 768px) {
            padding: 0.5rem 2rem;
        }

        &:last-child {
            border: 0;
        }

        &Left {
            font-size: 1rem;
            font-weight: 400;
            max-width: 70%;
        }

        &Right {
            font-size: 1.5rem;
            font-weight: 600;
            white-space: nowrap;
        }
    }
}
