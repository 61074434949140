import React, {useEffect, useState} from "react";
import {
    useQuery,
    useQueryClient,
} from 'react-query';
import {ApiService} from "../../services/ApiService";
import {ILatestOrder} from "../../interfaces/api";
import * as classes from "./paymentList.module.scss";

import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';

export const PaymentsList = (): JSX.Element => {

    const queryClient = useQueryClient();

    const { status, data, error } = useQuery(
        'latest',
        async () => {
            const res = await ApiService.getLatestOrders(20);
            return res.data;
        },
        {
            refetchInterval: parseInt(process.env.LATEST_INTERVAL || "20000"),
        }
    )

    return (
        <SimpleBar style={{ height: '340px' }} className={classes.paymentsList}>
            {
                data &&
                data.map((record: ILatestOrder) => {
                    const key = new Date(record.completedAt).getTime();
                    return (
                        <li key={key}
                            className={classes.paymentsListItem}>
                            <span className={classes.paymentsListItemLeft}>{record.name || "Wpłata anonimowa"}</span>
                            <span className={classes.paymentsListItemRight}>{record.amount} zł</span>
                        </li>
                    )
                })
            }
        </SimpleBar>
    )
};
