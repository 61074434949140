
.rulesIndent {
  padding-left: 4rem;
  margin-top: 1rem;
	font-size: 14px;

  &Small {
    font-size: 13px;

    ol {
      padding-left: 14px;
    }
  }
}