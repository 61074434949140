@import "src/scss/variables";

.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 82px 82px 82px;
    column-gap: 24px;
    row-gap: 24px;
    justify-items: stretch;
    justify-content: center;
    margin: 3rem 0 0 0;

    @media all and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 166px;
    }
}

.labelInput {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
}

.btn {
    background-color: $c_ice;
    color: white;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: background-color 310ms ease;

    &:hover {
        background-color: $c_brand;
    }

    @media all and (min-width: 768px) {
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        text-align: center;
    }

    &IconWrapper {
        width: 50px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 24px 0 24px;



        @media all and (min-width: 768px) {
            justify-content: center;
            width: 54px;
            margin: 0;
        }

        &Card {
            height: 35px;

            @media all and (min-width: 768px) {
                margin: 16px 0 12px
            }
        }
        &Transfer {
            height: 55px;


             @media all and (min-width: 768px) {
                 width: 80px;
                 margin: 4px 0 8px 28px;

             }
        }
        &Blik {
            height: 50px;
            margin-bottom: 8px;

             @media all and (min-width: 768px) {
                 width: 50px;
                 margin-bottom: 8px;
             }
        }
    }

    &Icon {
        max-width: 100%;
        max-height: 100%;
    }

    &Label {
        font-size: 16px;
        font-weight: 400;
    }

    &Selected {
        background-color: $c_accent !important;
    }
}
