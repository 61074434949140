import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

import * as classes from "./paymentFormRules.module.scss";
import {RULES} from "../../constans";

export const PaymentFormRules2 = (): JSX.Element => {

    const { register, setValue, watch, formState: { errors } } = useFormContext();

    const consentsGraphicAgreement = watch("consents.graphicAgreement");
    const marketingAgreement = watch("consents.marketingAgreement");
    const [consentsGraphicAgreementMoreOpened, setConsentsGraphicAgreementMoreOpened] = useState(false);
    const [consentsMarketingAgreementMoreOpened, setConsentsMartketingAgreementMoreOpened] = useState(false);
    const [consentsMartketingSubconsent3AgreementMoreOpened, setConsentsMartketingSubconsent3AgreementMoreOpened] = useState(false);

    const marketingAgreementsSubagreement1 = watch("consents.marketingAgreements.subagreement1");
    const marketingAgreementsSubagreement2 = watch("consents.marketingAgreements.subagreement2");
    const marketingAgreementsSubagreement3 = watch("consents.marketingAgreements.subagreement3");

    // watch primary agreement
    useEffect(() => {
        if (marketingAgreement) {
            // Select rules inside
            setValue("consents.marketingAgreements.subagreement1", true);
            setValue("consents.marketingAgreements.subagreement2", true);
            setValue("consents.marketingAgreements.subagreement3", true);
        }

    }, [marketingAgreement]);

    useEffect(() => {
        if (!marketingAgreementsSubagreement1) {
            setValue("consents.marketingAgreement", false);
        }
    }, [marketingAgreementsSubagreement1]);

    useEffect(() => {
        if (!marketingAgreementsSubagreement2) {
            setValue("consents.marketingAgreement", false);
        }
    }, [marketingAgreementsSubagreement2]);

    useEffect(() => {
        if (!marketingAgreementsSubagreement3) {
            setValue("consents.marketingAgreement", false);
        }
    }, [marketingAgreementsSubagreement3]);


    // Select inside rules
    useEffect(() => {
        if (
            marketingAgreementsSubagreement1 &&
                marketingAgreementsSubagreement2 &&
                marketingAgreementsSubagreement3
        ) {
            setValue("consents.marketingAgreement", true);
        }


    }, [marketingAgreementsSubagreement1, marketingAgreementsSubagreement2, marketingAgreementsSubagreement3]);


    // useEffect(() => {
    //     if (errors) {
    //         console.log(errors);
    //     }
    //
    // }, [errors]);

    function handleClickOpenRules (e) {
        e.preventDefault();
        setConsentsGraphicAgreementMoreOpened(!consentsGraphicAgreementMoreOpened);
    }

    function handleClickOpenMarketingRules (e) {
        e.preventDefault();
        setConsentsMartketingAgreementMoreOpened(!consentsMarketingAgreementMoreOpened);
    }

    function handleClickOpenMarketingSubconsent3Rules (e) {
        e.preventDefault();
        setConsentsMartketingSubconsent3AgreementMoreOpened(true);
    }

    return (
        <div>
            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" id="consents.isNotAnonymous" name={"consents.isNotAnonymous"} {...register("consents.isNotAnonymous")} />
                <label className="form-check-label" htmlFor="consents.isNotAnonymous">
                    { RULES.isNotAnonymous }
                </label>
            </div>

            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" id="consents.graphicAgreement" name={"consents.graphicAgreement"} {...register("consents.graphicAgreement")} />
                <label className="form-check-label" htmlFor="consents.graphicAgreement">
                    <span dangerouslySetInnerHTML={{__html: RULES.graphicAgreement[0]}} />&nbsp;

                    <a className={"u-link"} href={"#"} onClick={handleClickOpenRules}>
                        {
                            consentsGraphicAgreementMoreOpened ? "Zwiń" : "Czytaj więcej"
                        }
                    </a>

                </label>
            </div>
            {
                consentsGraphicAgreementMoreOpened &&
                <div className={classes.rulesIndent}>
                    <span dangerouslySetInnerHTML={{__html: RULES.graphicAgreement[1]}} />
                    <div className={"form-check-label"}><span dangerouslySetInnerHTML={{__html: RULES.graphicAgreement[2]}} /></div>

                </div>
            }

            <div className="form-check mt-3 mb-3">
                <input className="form-check-input" type="checkbox" id="consents.rules" name={"consents.rules"} {...register("consents.rules", {
                    required: true
                }) } />
                <label className="form-check-label" htmlFor="consents.rules">
                    <span dangerouslySetInnerHTML={{__html: RULES.rules}} />
                </label>
                {
                    errors?.consents?.rules &&
                    <div className="invalid-feedback">Zgoda jest wymagana</div>
                }
            </div>
        </div>
    )
};
